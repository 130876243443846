<template>
  <router-view> </router-view>
</template>

<script>
export default {
  name: "App",
  mountd() {
    if (localStorage.getItem("verification_id")) {
      this.$router.push("/");
    }
  },
};
</script>

<style>
/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}*/

#app {
  height: 100%;
  width: 100%;
}

body,
html {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}
</style>
